

/* Triangles */
.ss-style-triangles::before,
.ss-style-triangles::after {
  left: 50%;
  width: 100px;
  height: 100px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ss-style-triangles::before {
  top: -50px;
  background: teal;
}

.ss-style-triangles::after {
  bottom: -50px;
  z-index: 10;
  background: inherit;
}

/* Double Diagonal line */
.ss-style-doublediagonal {
  z-index: 1;
  padding-top: 6em;
  background: #004848;
}

.ss-style-doublediagonal::before,
.ss-style-doublediagonal::after {
  top: 0;
  left: -25%;
  z-index: -1;
  width: 150%;
  height: 75%;
  background: inherit;
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ss-style-doublediagonal::before {
  height: 50%;
  background: #003434;
  -webkit-transform: rotate(-3deg);
  transform: rotate(-3deg);
  -webkit-transform-origin: 3% 0;
  transform-origin: 3% 0;
}

/* Half Circle */
.ss-style-halfcircle::before,
.ss-style-halfcircle::after {
  left: 50%;
  z-index: 10;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: inherit;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ss-style-halfcircle::before {
  top: -50px;
}

.ss-style-halfcircle::after {
  bottom: -50px;
}

/* Big Triangle */
svg#bigTriangleColor {
  pointer-events: none;
}

#bigTriangleColor path {
  fill: #005c5c;
  stroke: #005c5c;
  stroke-width: 2;
}

/* Curves */
#curveUpColor path,
#curveDownColor path {
  fill: #005c5c;
  stroke: #005c5c;
}

/* Multiple Triangles */
.ss-style-multitriangles::before,
.ss-style-multitriangles::after {
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ss-style-multitriangles::before {
  top: -25px;
  background: inherit;
  box-shadow: -50px 50px 0 #005c5c, 50px -50px 0 #005c5c;
}

.ss-style-multitriangles::after {
  bottom: -25px;
  z-index: 10;
  background: inherit;
  box-shadow: -50px 50px 0 #005c5c, 50px -50px 0 #005c5c;
}

/* Rounded corners */
.ss-style-roundedcorners::before {
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  border-radius: 0 0 60px 60px;
  background: inherit;
}

/* Rounded center split and bottom rounded */
.ss-style-roundedsplit {
  padding-top: 7em;
  border-radius: 0 0 80px 80px;
}

.ss-style-roundedsplit::before,
.ss-style-roundedsplit::after {
  top: -70px;
  left: 0;
  z-index: 10;
  width: 50%;
  height: 70px;
  background: inherit;
}

.ss-style-roundedsplit::before {
  border-radius: 0 80px 0 0;
}

.ss-style-roundedsplit::after {
  left: 50%;
  border-radius: 80px 0 0 0;
}

/* Inverted rounded corners */
.ss-style-invertedrounded {
  margin-bottom: 90px;
  padding: 13em 10% 10em;
  border-radius: 0 0 0 90px;
}

.ss-style-invertedrounded::before,
.ss-style-invertedrounded::after {
  left: 0;
  z-index: -1;
  height: 90px;
  background: #005c5c;
}

.ss-style-invertedrounded::before {
  top: 100%;
  width: 100%;
  border-radius: 0 90px 0 0;
}

.ss-style-invertedrounded::after {
  bottom: 0;
  z-index: -1;
  width: 50%;
}

/* Zig Zag down */
.ss-style-zigzag::after, .ss-style-zigzag::before, .ss-style-zigzag-alt::after, .ss-style-zigzag-alt::before{
  right: 0;
  left: 0;
  z-index: 10;
  display: block;
  height: 90px;
  background-size: 50px 100%;
}
.ss-style-zigzag::before{
top: 82%;
background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #5b986b), color-stop(0.25, #5b986b));
background-image: linear-gradient(45deg, #5b986b 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(-45deg, #5b986b 25%, rgba(0, 0, 0, 0) 25%);
background-position: 50%
}

.ss-style-zigzag::after {
  top: 0%;
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #005c5c), color-stop(0.25, teal));
  background-image: linear-gradient(135deg, #f2f2f2 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(225deg, #f4f4f4 25%, rgba(0, 0, 0, 0) 25%);
  background-position: 50%;
}

.ss-style-zigzag-alt::before{
  top: 0%;
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #005c5c), color-stop(0.25, #5b986b));
  background-image: linear-gradient(135deg, #5b986b 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(225deg, #5b986b 25%, rgba(0, 0, 0, 0) 25%);
  background-position: 50%;
}
.ss-style-zigzag-alt::after{
  top: 82%;
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #f4f4f4), color-stop(0.25, f4f4f4));
  background-image: linear-gradient(45deg, #f4f4f4 25%, rgba(0, 0, 0, 0) 25%), linear-gradient(-45deg, #f4f4f4 25%, rgba(0, 0, 0, 0) 25%);
  background-position: 50%
}

/* Edges */
.ss-style-roundedges {
  padding-bottom: 16em;
}

.ss-style-roundedges::before,
.ss-style-roundedges::after {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: teal;
  top: -100px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ss-style-roundedges::before {
  left: -100px;
}

.ss-style-roundedges::after {
  right: -100px;
}

/* Slit */
.ss-style-slit {
  padding-top: 5em;
}

.ss-style-slit::before,
.ss-style-slit::after {
  width: 50.5%;
  width: -webkit-calc(50% + 10px);
  width: -moz-calc(50% + 10px);
  width: calc(50% + 10px);
  height: 160px;
  background: teal;
  top: -160px;
}

.ss-style-slit::before {
  left: -20px;
  -webkit-transform: skewX(10deg);
  transform: skewX(10deg);
  box-shadow: -10px -20px #005757;
}

.ss-style-slit::after {
  right: -20px;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
  box-shadow: 10px -20px #005757;
}

/* Big half circle */
#bigHalfCircle path {
  fill: #005c5c;
  stroke: #005c5c;
}

/* Big triangle with shadow */
#trianglePath1 {
  fill: #005c5c;
  stroke: #005c5c;
}

#trianglePath2 {
  fill: #003e3e;
  stroke: #003e3e;
}

/* Inclined Zig Zag */
.ss-style-inczigzag::before,
.ss-style-inczigzag::after {
  left: 0;
  width: 100%;
  height: 50px;
  background-size: 100px 100%;
}

.ss-style-inczigzag::before {
  top: 0;
  background-image: -webkit-gradient(linear, 0 0, 10% 100%, color-stop(0.5, #004848), color-stop(0.5, #005c5c));
  background-image: linear-gradient(15deg, #005c5c 50%, #008080 50%);
}

.ss-style-inczigzag::after {
  bottom: 0;
  background-image: -webkit-gradient(linear, 0 0, 10% 100%, color-stop(0.5, #005c5c), color-stop(0.5, teal));
  background-image: linear-gradient(15deg, #008080 50%, #005c5c 50%);
}

/* Clouds */
#clouds path {
  fill: #ecf0f1;
  stroke: #ecf0f1;
}

.clouds {
  background: #ecf0f1;
}
.clouds p {
  color: #333;
}
.clouds a {
  color: #004d4d;
  text-decoration: none;
}
.clouds a:hover {
  color: black;
}

/* Tagliatelle */
.ss-style-tagliatelle{
  right: 0;
  left: 0;
  z-index: 10;
  display: block;
  height: 90px;
  background-size: 50px 100%;
}

.ss-style-tagliatelle::before{
  content:"";
  background-position: 50%;;
left: 0;
width: 100%;
height: 25px;
background-image: -webkit-gradient(linear, 100% 0, 0 100%, color-stop(0.5, #00000000), color-stop(0.5, #f3f3f3));
background-image: linear-gradient(to right, #00000000 50%, #f3f3f3 50%);
-webkit-background-size: 130px 100%;
background-size: 50px 100%;
}

.ss-style-tagliatelle::after{
  content:"";
  background-position: 50%;;
  bottom:0;
left: 0;
width: 100%;
height: 25px;
background-image: -webkit-gradient(linear, 100% 0, 0 100%, color-stop(0.5, #5b986b), color-stop(0.5, #5b986b));
background-image: linear-gradient(to right, #ffffff00 50%, #5b986b 50%);
-webkit-background-size: 130px 100%;
background-size: 50px 100%;
}

/* sorrentino */

.ss-style-sorrentinos::before{
  height: 70px;
  background-image: url(/assets/img/sorrentino.svg);
  width: 100%;
  background-size: 70px 70px;
  top: -35px;
}
.ss-style-sorrentinos::after{
  height: 70px;
  background-image: url(/assets/img/sorrentino-white.svg);
  width: 100%;
  background-size: 70px 70px;
  top: calc(100% - 35px);
}
