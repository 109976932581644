@import "/var/www/lamandina.com/html/LaMandina/assets/css/variables/colors.scss";


@import 'dividers.scss';
@import 'unslider.scss';
@import 'unslider-dots.scss';
@import url('https://fonts.googleapis.com/css?family=Berkshire+Swash|Dancing+Script|Pacifico|Bree+Serif|Open+Sans+Condensed:300|Raleway');
body{
  background: #5b986b;
/* background-image: url("/assets/img/squares-texture.png") !important */
}
.ui.vertical.segment{
  border:none;
}
.order_now{
  position: fixed;
  right: 0;
  bottom: 300px;
  padding: 40px;
  background: white;
  color: green;
  font-size:20px;
  z-index:1000;
  box-shadow: 1px 1px 20px black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 4px dashed #5b986b;
  text-align: center;
}
.pageHeader {
  min-height: 500px;
  height: 500px;
  /* background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0.65)); */
  z-index: 1000;
  position: relative;
}

.header-pic {
  width: 25%;
  height: 100%;
  background-size: cover !important;
  display: inline-block;
  background-repeat: no-repeat;

  border-bottom-left-radius: 50% 10%;
border-bottom-right-radius: 50% 10%;
}

.header-pic-1 {
    border-bottom-left-radius: 50% 0%;
  background-image: url(/assets/img/media/pastas_rellenas/ravioli/2.jpg);
}

.header-pic-2 {
  background-image: url(/assets/img/media/pates/1.jpg);
  background-position: -100px 0px;
}

.header-pic-3 {
  background-image: url(/assets/img/media/pastas_rellenas/sorrentinos/2.jpg);
  background-position: -100px 0;
}

.header-pic-4 {
  background-image: url(/assets/img/media/zarzas/1.jpg);
  background-position: -120px 0;
  border-bottom-right-radius: 50% 0%;
}

.head {
  text-align: center;
  width: 100%;
  background:white;
  margin: 0 auto;
}

.social {
  position: absolute;
  right: 20px;
  top: 5px;
  list-style-type: none;
}

.social li {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin: 0 10px;
}

.social a {
  width: 100%;
  height: 100%;
  display: block;
  background-size: 100%;
  background-repeat: no-repeat;
}

.fb {
  background-image: url("/assets/img/fb.png");
}

.insta {
  background-image: url("/assets/img/insta.png");
}

.pinterest {
  background-image: url("/assets/img/pinterest.png");
}

.logo {
  padding: 30px;
  border-radius: 20px;
  width: 290px;
  height: 170px;
  background-repeat: no-repeat;
  margin: 20px 0;
  background-size: 100%;
  background-image: url("/assets/img/logo.png") !important;
  display: inline-block;
}

.slogan {
  display: block;
  color: #5b986b;
  margin-left: 60px;
  font-size: 30px;
  font-family: 'Pacifico', cursive;
  font-family: 'Berkshire Swash', cursive;
  font-family: 'Dancing Script', cursive;

}

.main-menu .item {
  padding: 10px 20px;
  color: white;
  font-size: 20px;
  font-family: 'Berkshire Swash', cursive;
  font-family: 'Bree Serif', serif;
  display: inline-block;
  border-bottom: 5px solid transparent;
}

.main-menu .item:hover {
  border-bottom: 5px solid white;
}

.main-menu {
  background: #37944f !important;
  background-image: url("/assets/img/squares-texture.png") !important;
  background-size: 300px !important;
  text-align: center;
}


.green_segment p,
.white_segment p {
  text-align: center !important;
  font-size: 1.33em!important;
  margin-bottom: 50px;
}

.green_segment p {
  padding: 30px;
  margin-top: 30px !important;
  border-radius: 5px;
  color:white;
}

.green_segment {
  color: rgb(12, 68, 27) !important;
    background: #5b986b !important;
  /* background-image: url("/assets/img/crisp-paper-ruffles.png") !important; */
    margin-top: -50px;
  padding-top: 50px;
    z-index: 100;
}
.green_segment:after {
    transform: skewY(-2deg);
    transform-origin: top left;
    background-color: #5b986b;
    /* background-image: url(/assets/imgs/squares-texture.png); */
    content: "";
    position: absolute;
    /* height: 135%; */
    width: 100%;
    top: -20%;
    left: 0;
    z-index: -1;
}
/* .green_segment:before{
    content: "";
    position: absolute;
    width: 240%;
    height: 100%;
    top: -140%;
    left: -70%;
    z-index: -1;
    background: url(/assets/imgs/crisp-paper-ruffles.png) !important;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.green_segment:after{
  content: "";
  position: absolute;
  width: 300%;
  height: 200%;
  top: -180%;
  left: -20%;
  z-index: -1;
  background: url(/assets/imgs/crisp-paper-ruffles.png) !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
} */
.green_segment .header {
  color: white !important;
}

.white_segment {
  color: rgb(58, 58, 58) !important;
  background-color:white;
  background-image: url("/assets/img/dust_scratches.png") !important;
}

.white_segment .header,
.white_segment {
  color: rgb(58, 58, 58) !important;
  color: rgb(78, 132, 92) !important;
}

.left_column {
  background-color: #5b986b !important;
  color: rgb(12, 68, 27) !important;

}

.green_segment,
.white_segment {
  padding: 10px 0 50px !important;
}

.green_segment h1,
.white_segment h1 {
  margin-top: 100px !important;
  font-size: 4em !important;
}

.green_segment h2,
.white_segment h2 {
  margin: 40px 0 !important;
  font-size: 2em !important;
}
.green_segment h3,
.white_segment h3 {
 font-size: 2em !important;
}


.green_segment .header,
.white_segment .header,
.left_column h3,
.right_column h3 {
  font-family: 'Bree Serif', serif !important;
}

.right_column {
  background-color: #51489a !important;
  color: #1f1854 !important;
}

.pic_section{
  min-height: 500px;
  height: 500px;
  /* background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0.65)); */
}
.divider{
    position:relative;
}
.divider::before,
.divider::after {
  position: absolute;
  content: '';
  pointer-events: none;
}
.triangle{
  display:block;
position:absolute;
content:'';
top: -25px;
background: inherit;
box-shadow: -50px 50px 0 #3498db, 50px -50px 0 #3498db;
left: 50%;
width: 50px;
height: 50px;
-webkit-transform: translateX(-50%) rotate(45deg);
transform: translateX(-50%) rotate(45deg);

}
.section-pic{
  width:100%;
  height: 100%;
  background-size: cover !important;
  background-attachment: fixed;
  display: inline-block;
  background-repeat: no-repeat;
}

.section-pic-1 {
  background-image: url(/assets/img/media/pastas_rellenas/ravioli/3.jpg);
}

.section-pic-2 {
  background-image: url(/assets/img/media/pates/1.jpg);
}

.section-pic-3 {
  background-image: url(/assets/img/media/pastas_rellenas/sorrentinos/2.jpg);
}

.section-pic-4 {
  background-image: url(/assets/img/media/zarzas/1.jpg);
}
.home_gallery {
  margin: 50px 0 !important;
  text-align: center !important;
}

.home_gallery img {
  height: 250px !important;
  border: 10px solid white !important;
}

.people {
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0 0 10px;
}

.people li {
  width: 140px;
  vertical-align: top;
}

.person {
  display: inline-block;
  margin: 30px 50px;
  text-align: center;
}

.portrait {
  margin: 0 auto;
  width: 130px;
  height: 130px;
  background-size: 100%;
  border-radius: 50%;
  filter: grayscale(100);
}
.footer.segment {
 padding: 5em 0em;
}

.left_column, .right_column{
      padding: 60px 0!important;
}

.left_column h3, .right_column h3{
    font-size:2em;
}
.left_column p, .right_column p{
    font-size:1.3em;
}

.producto{
  width:1000px;
  height:500px;
    border-top-right-radius:30px;
}
.producto .producto_contenido{
  width:500px;
  height:500px;
  padding-top:150px;
  background:white;
  float:left;
}
.producto:nth-child(1)>div:nth-child(1){
  border-top-left-radius:30px;
}
.producto:nth-child(1)>div:nth-child(2){
  border-top-right-radius:30px;
}
.producto:nth-last-child(1)>div:nth-child(1){
  border-bottom-left-radius:30px;
}
.producto:nth-last-child(1)>div:nth-child(2){
  border-bottom-right-radius:30px;
}

.producto .producto_contenido .nombre{
  font-size: 4em;
  width: 500px;
  height: 50px;
  text-align: center;
  color: #5b986a;
  font-family: 'Bree Serif', serif;
}
.producto .producto_contenido .descripcion{
  font-size: 1em;
  width: 250px;
  margin:20px auto;
  text-align: center;
  color: #5b986a;
  font-family: 'Bree Serif', serif;
}
.producto .producto_contenido .precio{
  font-size:1.5em;
  width:150px;
  padding:10px;
  margin:0 auto;
  background:white;
  border:4px solid #5b986a;
  text-align:center;
  color: #5b986a;
  font-family: 'Bree Serif', serif;
  transition:all 0.1s linear;
  display:block;
}
.producto .producto_contenido .precio:hover{
  background:#5b986a;
  color:white;
}
.white_segment .producto .producto_contenido{
  width:500px;
  height:500px;
  padding-top:150px;
  background:#5b986a;
  float:left;
}
.white_segment .producto .producto_contenido .nombre,
.white_segment .producto .producto_contenido .descripcion,
.white_segment .producto .producto_contenido .precio{
color:white;
}
.white_segment .producto .producto_contenido .precio{
  background-color:#5b986a;
  border:4px solid white;
}
.white_segment .producto .producto_contenido .precio:hover{
  background-color:white;
  color:#5b986a;
}
.producto .producto_pic{
  width:500px;
  height:500px;
  background:white;
  float:left;
  background-size:cover;
}
.producto_pic ul{
  list-style: none;
}
.producto_pic li img{
  display:none;

}
.producto_pic li:nth-child(1) img{
  display:block;
  width:700px;
  height:500px;
}
.unslider{
  float:left;
}
.unslider .producto_pic li img{
  width:700px;
  height:500px;
  display:block;
}
@media only screen and (max-width: 1200px) {
  .unslider{
    width:50%;
    height:100%;
  }
  .producto{
    width:100%;
    height:400px;
  }
  .producto .producto_pic{
    width:100%;
    height:100%;
    display:block;
    float:none;
  }
  .producto .producto_contenido .nombre {
    font-size: 2em;
    width: 50%;
    height: 50px;
  }
  .producto_pic li:nth-child(1) img{
    width:150%;
    height:100%;
  }
  .unslider .producto_pic li{
      height:400px;
  }
  .unslider .producto_pic li img{
    width:150%;
    height:100%;
  }
.producto .producto_contenido{
  padding-top: 20px;
  width:50%;
  height:100%;
}
}
@media only screen and (max-width: 700px) {

  .producto{
    width:500px;
    height:250px;
    margin:0 auto;
  }
  .producto .producto_pic{
    width:250px;
    height:250px;
    display:block;
    float:none;
  }
  .producto .producto_contenido .nombre {
    font-size: 2em;
    width: 250px;
    height: 50px;
  }
   .producto .producto_contenido .descripcion{
      font-size: 1.5em;
  }
  .producto_pic li:nth-child(1) img{
    width:350px;
    height:250px;
  }
  .unslider .producto_pic li img{
    width:350px;
    height:250px;
  }
.producto .producto_contenido{
  padding-top: 20px;
  width:250px;
  height:250px;
}
}
@media only screen and (max-width: 500px) {

  .producto{
    width:100%;
    height:500px;
    margin:0 auto;
  }
  .producto .producto_pic{
    width:100%;
    height:250px;
    display:inline-block;
    float:none;
  }
  .producto .producto_contenido, .white_segment .producto .producto_contenido{
    padding-top: 50px;
    width:100%;
    height:500px;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
  }

  .unslider{
    width:100%;
    height:250px;
    overflow:hidden!important;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
  }
  .unslider .producto_pic li{
      height:500px;
  }
  .producto .producto_contenido .nombre {
    font-size: 3em;
    width: 100%;
    height:100px;
  }
  .producto .producto_contenido .descripcion{
     font-size: 1.8em;
     width:90%;
     line-height:1.3em;
 }
  .producto_pic li:nth-child(1) img{
    width:700px;
    height:500px;
    overflow:hidden;
  }
  .unslider .producto_pic li img{
    width:700px;
    height:500px;
  }

}
